<template>
  <div id="app">
    <div class="relative overflow-hidden">
      <div class="absolute h-full w-full">
        <div class="fog-img first absolute"></div>
        <div class="fog-img second absolute"></div>
      </div>

      <div id="nav" class="fixed z-20" :style="{ opacity: Math.min(pageOffset.current / (nav.height * 4), 1) }">
        <div class="px-4 sm:px-8 py-4 flex flex-wrap flex-col w-full fixed shadow-lg" style="background-color: #C0C1C5">
          <div class="container m-auto flex flex-wrap justify-between">
            <img src="./assets/img/logo.svg" alt="Brad Ahrens dot com" title="Brad Ahrens" class="w-12 sm:w-auto" loading="lazy">
            <div class="flex flex-wrap items-center">
              <a href="#content" class="pl-5 z-10 text-sm sm:text-base">Content</a>
              <a href="#projects" class="pl-5 z-10 text-sm sm:text-base">Projects</a>
              <a href="#contact" class="pl-5 z-10 text-sm sm:text-base">Contact</a>
            </div>
          </div>
        </div>
      </div>

      <div class="hero flex flex-wrap flex-col px-4 sm:px-8 py-4 bg-cover bg-no-repeat bg-center bg-fixed h-screen">
        <div class="container m-auto flex flex-wrap justify-between">
          <img src="./assets/img/logo.svg" alt="Brad Ahrens dot com" title="Brad Ahrens" class="w-12 sm:w-auto" loading="lazy">
          <div class="flex flex-wrap items-center">
            <a href="#content" class="pl-5 z-10 text-sm sm:text-base">Content</a>
            <a href="#projects" class="pl-5 z-10 text-sm sm:text-base">Projects</a>
            <a href="#contact" class="pl-5 z-10 text-sm sm:text-base">Contact</a>
          </div>
        </div>
        <div class="flex-1 flex flex-1 flex-wrap items-center justify-center text-center">
          <h1 class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl -mt-32 md:-mt-64" style="text-shadow: 5px 0px 15px rgba(255, 255, 255, 0.6);"
            :style="{ opacity: ((h1YOffset.current * 2) / screenheight) }">
            Brad Ahrens <br>Web Developer
          </h1>
        </div>
      </div>
    </div>

    <span id="content"></span>
    <div class="relative">
      <div class="w-full px-0 md:px-4 lg:px-8 pb-10">
        <transition name="slideUp">
          <div class="container m-auto -mt-32 w-full bg-gray-200 rounded-0 sm:rounded-lg shadow-2xl px-0 py-8 sm:px-8 sm:py-8 lg:px-16 lg:py-16"
            v-show="pageOffset.current > 0">
            <h2 class="text-center text-base md:text-xl lg:text-2xl max-w-3xl m-auto mb-8 lg:mb-16 px-5">
              Passionate about developing, I create programming tutorials for social media channels on
              <a class="underline" rel="noreferrer" href="https://www.youtube.com/c/BradleyAhrens" target="_blank">YouTube</a>
              and
              <a class="underline" rel="noreferrer" href="https://medium.com/@bradleyjamesahrens" target="_blank">Medium</a>
            </h2>
            <div class="flex flex-wrap mb-16">
              <div class="w-full lg:w-1/2 mb-6 lg:mb-0 flex flex-col items-center justify-center">
                <p class="text-sm md:text-base lg:text-xl max-w-md text-center lg:text-left">
                  I am currently working on a series to help developers prepare for the Laravel Certification Exam.
                </p>
                <a rel="noreferrer" class="px-4 py-2 mt-4 bg-red-400 text-white rounded shadow-lg hover:bg-red-600 hover:shadow-xl"
                  href="https://www.youtube.com/watch?v=Ko-xBbXxSXo&list=PLreYfy6R-5ckUjeS-nld1JDSEqCy-htt0" target="_blank">
                  Check it out
                </a>
              </div>
              <div class="w-full lg:w-1/2">
                <a rel="noreferrer" href="https://www.youtube.com/watch?v=Ko-xBbXxSXo&list=PLreYfy6R-5ckUjeS-nld1JDSEqCy-htt0" target="_blank">
                  <img src="./assets/img/youtube_laravel.png"
                    class="max-w-xs lg:max-w-md m-auto"
                    alt="Laravel Certification - Prep Course" title="Laravel Certification - Prep Course" loading="lazy">
                </a>
              </div>
            </div>
            <div class="flex flex-wrap flex-row-reverse">
              <div class="w-full lg:w-1/2 mb-6 lg:mb-0 flex flex-col items-center justify-center">
                <p class="text-sm md:text-base lg:text-xl max-w-md text-center lg:text-right">
                  I am also developing content about various AWS services such as Elastic Beanstalk, Amplify, and Code Pipeline
                </p>
                <a rel="noreferrer" class="px-4 py-2 mt-4 bg-red-400 text-white rounded shadow-lg hover:bg-red-600 hover:shadow-xl"
                  href="https://www.youtube.com/watch?v=FdjOAJ6jrzE&list=PLreYfy6R-5cmhnS8HBER2xdO8PQ6JLAaT" target="_blank">
                  Find out more
                </a>
              </div>
              <div class="w-full lg:w-1/2">
                <a rel="noreferrer" href="https://www.youtube.com/watch?v=FdjOAJ6jrzE&list=PLreYfy6R-5cmhnS8HBER2xdO8PQ6JLAaT" target="_blank">
                  <img src="./assets/img/youtube_aws.png"
                    class="max-w-xs lg:max-w-md m-auto"
                    alt="Laravel Certification - Prep Course" title="Laravel Certification - Prep Course" loading="lazy">
                </a>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <span id="projects"></span>
    <div class="relative">
      <transition name="slideUp">
      <div class="w-full px-0 md:px-4 lg:px-8 md:pb-10" v-show="pageOffset.current > projectsOffset.current">
        <div class="container m-auto w-full bg-gray-200 rounded-0 sm:rounded-lg shadow-2xl px-0 py-8 sm:px-8 sm:py-8 lg:px-16 lg:py-16 md:-mb-32 relative z-10">
          <h2 class="text-center text-base md:text-xl lg:text-2xl max-w-3xl m-auto mb-8 lg:mb-16 px-5">
            Outside of work, I enjoy developing side projects. They are a creative escape, which also helps me develop my skills and learn new things.
          </h2>
          <div class="flex flex-wrap mb-16">
            <div class="w-full md:w-1/3 flex flex-col items-center justify-center">
              <img src="./assets/img/albergodiieri.png" alt="Project" title="Project" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 flex flex-col items-center justify-center">
              <img src="./assets/img/bradahrens.png" alt="Project" title="Project" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 flex flex-col items-center justify-center">
              <img src="./assets/img/vybera.png" alt="Project" title="Project" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
          </div>
        </div>
      </div>
      </transition>
    </div>

    <span id="skills"></span>
    <div class="relative">
      <transition name="slideUp">
      <div class="w-full px-0 md:px-4 lg:px-8 md:pb-10 mt-12 md:mt-32" v-show="pageOffset.current > projectsOffset.current">
        <div class="container m-auto w-full bg-gray-200 rounded-0 sm:rounded-lg shadow-2xl px-0 py-8 sm:px-8 sm:py-8 lg:px-16 lg:py-16 md:-mb-32 relative z-10">
          <h2 class="text-center text-base md:text-xl lg:text-2xl max-w-3xl m-auto mb-8 lg:mb-16 px-5">
            I love learning and keeping up-to-date with the latest technologies. Here are a few that I use regularly.
          </h2>
          <div class="flex flex-wrap mb-16">
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/aws.svg" alt="Amazon Web Services" title="Amazon Web Services" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/laravel.svg" alt="Laravel" title="Laravel" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/php.svg" alt="PHP" title="PHP" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/vue.svg" alt="Vue" title="Vue" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/next.svg" alt="Next" title="Next" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/react.svg" alt="React" title="React" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/github.svg" alt="Jenkins" title="Jenkins" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/dart.svg" alt="Dart" title="Dart" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/flutter.svg" alt="Flutter" title="Flutter" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/sass.svg" alt="Sass" title="Sass" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/tailwind.svg" alt="Tailwind" title="Tailwind" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
            <div class="w-full md:w-1/3 p-10 flex flex-col items-center justify-center">
              <img src="./assets/img/tech_stack/bootstrap.svg" alt="Bootstrap" title="Bootstrap" class="w-1/3 md:w-1/2" loading="lazy">
            </div>
          </div>
        </div>
      </div>
      </transition>
    </div>

    <div class="relative overflow-hidden">
      <div class="absolute h-full w-full">
        <div class="fog-img first absolute"></div>
        <div class="fog-img second absolute"></div>
      </div>
      <span id="contact"></span>
      <div class="prefooter flex flex-wrap flex-col md:px-8 pt-16 sm:pt-32 md:pt-56 md:pb-32 bg-cover bg-no-repeat bg-center bg-fixed">
        <transition name="fade">
          <form class="w-full md:px-8 pb-10" @submit.prevent="submit">
            <div class="container m-auto w-full lg:w-2/3 bg-blue-dark sm:rounded-lg shadow-2xl px-0 sm:px-16 py-8 sm:py-16 text-white relative z-10 bg-opacity-75">
              <h2 class="text-center text-xl sm:text-2xl max-w-3xl m-auto mb-8 sm:mb-16 text-gray-dark">
                Contact me
              </h2>
              <div class="flex flex-wrap">
                <div class="w-full md:w-1/2">
                  <label class="block px-2">
                    <p class="text-red-500 text-xs -mb-3" v-if="$v.form.firstName.$invalid && $v.form.firstName.$dirty">First Name Required</p>
                    <p class="text-gray-dark text-xs -mb-3 opacity-0" :class="{ 'opacity-100' : form.firstName}" v-else>First Name</p>
                    <input type="text" placeholder="First Name"
                      class="form-input mt-1 block w-full bg-transparent focus:outline-none border-b border-gray-dark h-8 mb-2 sm:mb-8 text-lg"
                      :disabled="formSent"
                      :class="{
                        'border-red-500' : errors.includes('firstName') || ($v.form.firstName.$invalid && $v.form.firstName.$dirty),
                        'placeholder-gray-dark' : !errors.includes('firstName') && !($v.form.firstName.$invalid && $v.form.firstName.$dirty),
                        'placeholder-transparent' : $v.form.firstName.$invalid && $v.form.firstName.$dirty
                      }"
                      @blur="$v.form.firstName.$touch()"
                      v-model="form.firstName">
                  </label>
                </div>
                <div class="w-full md:w-1/2">
                  <label class="block px-2">
                    <p class="text-red-500 text-xs -mb-3" v-if="$v.form.lastName.$invalid && $v.form.lastName.$dirty">Last Name Required</p>
                    <p class="text-gray-dark text-xs -mb-3 opacity-0" :class="{ 'opacity-100' : form.lastName}" v-else>Last Name</p>
                    <input type="text" placeholder="Last Name"
                      class="form-input mt-1 block w-full bg-transparent focus:outline-none border-b border-gray-dark h-8 mb-2 sm:mb-8 text-lg"
                      :disabled="formSent"
                      :class="{
                        'border-red-500' : errors.includes('lastName') || ($v.form.lastName.$invalid && $v.form.lastName.$dirty),
                        'placeholder-gray-dark' : !errors.includes('lastName') && !($v.form.lastName.$invalid && $v.form.lastName.$dirty),
                        'placeholder-transparent' : $v.form.lastName.$invalid && $v.form.lastName.$dirty
                      }"
                      @blur="$v.form.lastName.$touch()"
                      v-model="form.lastName">
                  </label>
                </div>
                <div class="w-full">
                  <label class="block px-2">
                    <p class="text-red-500 text-xs -mb-3" v-if="$v.form.email.$invalid && $v.form.email.$dirty">Email Address Required</p>
                    <p class="text-red-500 text-xs -mb-3 opacity-0" :class="{ 'opacity-100' : form.email}" v-else-if="errors.includes('email')">Invalid Email Address</p>
                    <p class="text-gray-dark text-xs -mb-3 opacity-0" :class="{ 'opacity-100' : form.email}" v-else>Email Address</p>
                    <input type="email" placeholder="Email Address"
                      class="form-input mt-1 block w-full bg-transparent focus:outline-none border-b border-gray-dark h-8 mb-2 sm:mb-8 text-lg"
                      :disabled="formSent"
                      :class="{
                        'border-red-500' : errors.includes('email') || ($v.form.email.$invalid && $v.form.email.$dirty),
                        'placeholder-gray-dark' : !errors.includes('email') && !($v.form.email.$invalid && $v.form.email.$dirty),
                        'placeholder-transparent' : $v.form.email.$invalid && $v.form.email.$dirty
                      }"
                      @keydown="removeError('email')"
                      @blur="$v.form.email.$touch()"
                      v-model="form.email">
                  </label>
                </div>
                <div class="w-full">
                  <label class="block px-2">
                    <p class="text-red-500 text-xs -mb-3" v-if="$v.form.message.$invalid && $v.form.message.$dirty">Message Required</p>
                    <p class="text-gray-dark text-xs -mb-1 opacity-0" :class="{ 'opacity-100' : form.message}" v-else>Message</p>
                    <textarea rows="3" placeholder="Message"
                      class="form-textarea mt-1 block w-full bg-transparent focus:outline-none border-b border-gray-dark mb-4 sm:mb-8 text-lg resize-none"
                      :disabled="formSent"
                      :class="{
                        'border-red-500' : errors.includes('message') || ($v.form.message.$invalid && $v.form.message.$dirty),
                        'placeholder-gray-dark' : !errors.includes('message') && !($v.form.message.$invalid && $v.form.message.$dirty),
                        'placeholder-transparent' : $v.form.message.$invalid && $v.form.message.$dirty
                      }"
                      @blur="$v.form.message.$touch()"
                      v-model="form.message" >
                    </textarea>
                  </label>
                </div>
                <div class="w-full flex justify-center md:justify-end px-2">
                  <transition name="fade" mode="out-in">
                    <p class="py-4" v-if="formSent">Thanks {{ form.firstName }}! We'll be in touch.</p>
                    <button type="submit"
                      :disabled="formSending"
                      class="flex bg-gray-dark text-white px-10 py-4 rounded-lg hover:bg-opacity-75 focus:outline-none focus:border border-white"
                      v-else>
                      <svg v-if="formSending" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Send Message</span>
                    </button>
                  </transition>
                </div>
              </div>
            </div>
          </form>
        </transition>

      </div>
    </div>

    <footer class="bg-gray-dark h-24">
      <div class="container px-4 sm:px-8 m-auto flex flex-wrap h-full">
        <div class="w-1/4 flex items-center">
          <a rel="noreferrer" href="https://www.youtube.com/c/BradleyAhrens" target="_blank">
            <img src="./assets/img/me.png" alt="Brad Ahrens" title="Brad Ahrens" class="h-10 sm:h-16" loading="lazy"
              @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{animate__headShake : isHovering}">
          </a>
        </div>
        <div class="w-3/4 sm:w-1/2 flex items-center justify-end sm:justify-center icons">
          <a rel="noreferrer" href="https://www.youtube.com/c/BradleyAhrens" target="_blank">
            <img src="./assets/img/youtube.svg" alt="YouTube" title="YouTube" id="youtube" class="mx-2 hover:opacity-75" loading="lazy">
          </a>
          <a rel="noreferrer" href="https://github.com/brada1703" target="_blank">
            <img src="./assets/img/github.svg" alt="GitHub" title="GitHub" id="github" class="mx-2 hover:opacity-75" loading="lazy">
          </a>
          <a rel="noreferrer" href="https://www.linkedin.com/in/bradleyjamesahrens/" target="_blank">
            <img src="./assets/img/linkedin.svg" alt="LinkedIn" title="LinkedIn" id="linkedin" class="mx-2 hover:opacity-75" loading="lazy">
          </a>
          <a rel="noreferrer" href="https://medium.com/@bradleyjamesahrens" target="_blank">
            <img src="./assets/img/medium.svg" alt="Medium" title="Medium" id="medium" class="mx-2 hover:opacity-75" loading="lazy">
          </a>
          <a rel="noreferrer" href="https://stackoverflow.com/users/6390152/almostpitt" target="_blank">
            <img src="./assets/img/stackoverflow.svg" alt="Stack Overflow" title="Stack Overflow" id="stackoverflow" class="mx-2 hover:opacity-75" loading="lazy">
          </a>
        </div>
      </div>
    </footer>

  </div>
</template>

<script src="https://www.google.com/recaptcha/api.js"></script>
<script>
import axios from 'axios';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import DeviceDetector from 'device-detector-js';
const device = (new DeviceDetector()).parse(navigator.userAgent);
export default {
  data() {
    return {
      errors: [],
      formSent: false,
      formSending: false,
      isHovering: false,
      screenheight: '',
      nav: {
        height: 82,
      },
      pageOffset: {
        start: 0,
        current: 0,
      },
      h1YOffset: {
        start: 0,
        current: 0,
      },
      contentOffset: {
        start: 0,
        current: 0,
      },
      projectsOffset: {
        start: 0,
        current: 0,
      },
      form: {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        ip: '',
        utm_source: new URL(location.href).searchParams.get('utm_source') ?? '',
        utm_campaign: new URL(location.href).searchParams.get('utm_campaign') ?? '',
        utm_medium: new URL(location.href).searchParams.get('utm_medium') ?? '',
        utm_content: new URL(location.href).searchParams.get('utm_content') ?? '',
        utm_term: new URL(location.href).searchParams.get('utm_term') ?? '',
        ua_device: device.device.type ?? '',
        ua_browser: `${device.client.name} - ${device.client.version}` ?? '',
        ua_os: `${device.os.name} - ${device.os.version}` ?? '',
        user_agent: JSON.stringify(device),
      },
    }
  },
  validations: {
    form: {
      firstName: { required, minLength: minLength(2), maxLength: maxLength(20) },
      lastName: { required, minLength: minLength(2), maxLength: maxLength(20) },
      email: { required, email },
      message: { required, minLength: minLength(2), maxLength: maxLength(255) },
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted () {
    this.h1YOffset.start = Math.max(document.querySelector('h1').getBoundingClientRect().top, 0);
    this.h1YOffset.current = document.querySelector('h1').getBoundingClientRect().top;
    this.screenheight = screen.height;
    this.getIp();
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      this.pageOffset.current = window.pageYOffset;
      this.h1YOffset.current = Math.max(document.querySelector('h1').getBoundingClientRect().top, 0);
      this.contentOffset.current = document.querySelector('#content').getBoundingClientRect().top;
      this.projectsOffset.current = document.querySelector('#projects').getBoundingClientRect().top;
    },
    getIp(){
      fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(response => { this.form.ip = response.ip });
    },
    removeError(field) {
      this.errors = this.errors.filter(name => {
        return name !== field;
      });
    },
    submit(){
      this.formSending = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
      axios.post('https://2yn9i0drof.execute-api.eu-west-1.amazonaws.com/dev/contact', this.form)
        .then(response => {if(response.data.ResponseMetadata) this.formSent = true; this.formSending = false; })
        .catch(error => {
          // console.log(error);
          // console.log(error.response);
          // console.log(error.response.data);
          // console.log(error.response.data.errors);
          this.errors = Object.keys(error.response.data.errors);
          this.formSending = false;
        });
      }
    },
  },
}


</script>

<style lang="scss">
@import "assets/css/styles.css";
</style>
